import { CDSStatusIconProps } from '@ciscodesignsystems/cds-react-icons';

export enum MappingStatuses {
  Added = 'added',
  Changed = 'changed',
  Deleted = 'deleted',
  Unchanged = 'unchanged',
}

export const statusIconConfig: Record<
  MappingStatuses,
  { iconStatus: CDSStatusIconProps['status']; text: string }
> = {
  [MappingStatuses.Added]: {
    iconStatus: 'positive',
    text: 'added',
  },
  [MappingStatuses.Deleted]: {
    iconStatus: 'negative',
    text: 'deleted',
  },
  [MappingStatuses.Changed]: {
    iconStatus: 'warning',
    text: 'changed',
  },
  [MappingStatuses.Unchanged]: {
    iconStatus: 'inactive',
    text: 'unchanged',
  },
};
