import {
  IVerifyIdpResponse,
  ICreateIdpResponse,
  IIdpNotification,
} from 'src/app/interfaces/IIdentityProvider';
import {
  getActionFactory,
  getAsyncActionFactory,
} from '../../core/redux/utils/action-class-factory';

const createAction = getActionFactory('idp');
const createAsyncAction = getAsyncActionFactory('idp');

export const FetchIdentityProviders = createAsyncAction<null, null>(
  'FETCH_ALL_IDPS'
);

export const VerifyExternalIdp = createAsyncAction<string, IVerifyIdpResponse>(
  'VERIFY_EXTERNAL_IDP'
);

export const CreateExternalIdp = createAsyncAction<string, ICreateIdpResponse>(
  'CREATE_EXTERNAL_IDP'
);

export const ChangeIpdIdInputValue = createAction<string | null>(
  'CHANGE_IDP_ID_INPUT_VALUE'
);

export const ShowAddExternalIdpModal = createAction(
  'SHOW_ADD_EXTERNAL_IDP_MODAL'
);

export const HideAddExternalIdpModal = createAction(
  'HIDE_ADD_EXTERNAL_IDP_MODAL'
);

export const ChangeAddExternalIdpModalStep = createAction(
  'CHANGE_ADD_EXTERNAL_IDP_MODAL_STEP'
);

export const ClearAddExternalIdpData = createAction(
  'CLEAR_ADD_EXTERNAL_IDP_DATA'
);

export const AddIdpNotification = createAction<{
  data: IIdpNotification;
}>('ADD_IDP_NOTIFICATION');

export const HideIdpNotification = createAction('HIDE_IDP_NOTIFICATION');

export const FetchExternalIdentityProviders = createAsyncAction<null, null>(
  'FETCH_ALL_EXTERNAL_IDPS'
);
