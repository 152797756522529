import { CDSOption } from '@ciscodesignsystems/cds-react-select';
import _toUpper from 'lodash/toUpper';
import { createSelector } from 'reselect';
import { ProductKeys } from 'src/app/enums/Products';
import { FEATURE_FLAG_PREFIX } from 'src/app/utils/constant';
import {
  getProductsManadgedByKpa,
  mapProductCapabilitiesToTableData,
} from './helpers';
import { initialState } from './reducer';
import { IState } from '../../core/redux/interfaces';
import { UserRoleType } from '../../enums/UserRoles';
import {
  IRoleDetails,
  IUserRolesState,
  PermissionTableScopes,
  IBundleRoleDetails,
} from '../../interfaces/IUserRoles';
import { FetchAllUsers } from '../common/actions';
import {
  getAllProvisionedProductsInstances,
  getFeatureFlagsSet,
} from '../common/selectors';
import { getIsCurrentActionLoading } from '../loader/selectors';

import {
  getKPAManagedTenants,
  getIsKPA,
  getAllRolesByExistingProducts,
  getKPAManagedProductsAndRoles,
} from '../users/selectors';

export const getState = (state: IState): IUserRolesState =>
  state.userRoles || initialState;

export const getSelectedUserRoles = createSelector(
  [getState],
  ({ selectedUserRoles }) => selectedUserRoles
);

export const getEditingUserRole = createSelector(
  [getState],
  ({ editingUserRole }) => editingUserRole
);

export const getAssignUserRolesDrawerData = createSelector(
  [getState],
  ({ assignUserRolesDrawerData }) => assignUserRolesDrawerData
);

export const getRolesTableConfigData = createSelector(
  [getState],
  ({ rolesTableConfigData }) => rolesTableConfigData
);

export const getUserRoles = createSelector(
  [getState],
  ({ userRoles }) => userRoles
);

export const getAllGroups = createSelector(
  [getState],
  ({ allGroups }) => allGroups
);

export const getIsUserRolesLoading = createSelector(
  [getUserRoles],
  (userRoles) => userRoles.loading
);

export const getUserRolesLoadingErrors = createSelector(
  [getUserRoles],
  (userRoles) => userRoles.error
);

export const getRoleToUsersRelations = createSelector(
  [getState],
  ({ roleToUsersRelations }) => roleToUsersRelations
);

export const getUnassignUserFromRole = createSelector(
  [getState],
  ({ unassignUserFromRole }) => unassignUserFromRole
);

export const getUnassignGroupFromRole = createSelector(
  [getState],
  ({ unassignGroupFromRole }) => unassignGroupFromRole
);

export const getRoleToGroupsRelations = createSelector(
  [getState],
  ({ roleToGroupsRelations }) => roleToGroupsRelations
);

export const getUserRolesTableLoading = createSelector(
  [
    getUserRoles,
    (state) => getIsCurrentActionLoading(state, FetchAllUsers.Pending.TYPE),
  ],
  (userRoles, isAllUsersLoading) => userRoles.loading || isAllUsersLoading
);

export const getUnassignUserFromRoleTableLoading = createSelector(
  [getUserRoles, getRoleToUsersRelations, getUnassignUserFromRole],
  (userRoles, roleToUsersRelations, unassignUserFromRole) =>
    userRoles.loading ||
    roleToUsersRelations.loading ||
    unassignUserFromRole.loading
);

export const getUnassignGroupFromRoleTableLoading = createSelector(
  [getUnassignGroupFromRole, getUserRoles, getRoleToGroupsRelations],
  (unassignGroupFromRole, userRoles, roleToGroupsRelations) =>
    unassignGroupFromRole.loading ||
    userRoles.loading ||
    roleToGroupsRelations.loading
);

export const getAssignRolesDrawerLoading = createSelector(
  [
    getAllGroups,
    getSelectedUserRoles,
    (state) => getIsCurrentActionLoading(state, FetchAllUsers.Pending.TYPE),
  ],
  (allGroups, selectedUserRoles, isAllUsersLoading) =>
    allGroups.loading || selectedUserRoles.loading || isAllUsersLoading
);

export const getCustomRoleState = createSelector(
  [getState],
  ({ customRoles }) => customRoles
);

export const getIsDeleteUserRoleModalVisible = createSelector(
  [getCustomRoleState],
  ({ isDeleteUserRoleModalVisible }) => ({
    isDeleteUserRoleModalVisible,
  })
);

export const getActiveDeleteUserRole = createSelector(
  [getCustomRoleState],
  ({ deleteCustomUserRole }) => deleteCustomUserRole
);

export const getDeleteUserRoleModalData = createSelector(
  [getCustomRoleState],
  ({ deleteCustomUserRole }) => ({
    roleName: deleteCustomUserRole.roleDisplayName,
    roleId: deleteCustomUserRole.id,
  })
);

export const getUserRoleNotification = createSelector(
  [getState],
  ({ notification }) => ({
    notificationType: notification.type,
    roleName: notification.data,
    options: notification.options,
  })
);

export const getProductPermissions = createSelector(
  [getCustomRoleState],
  ({ productPermissions }) => productPermissions
);

export const getFilteredProductPermissions = createSelector(
  [getCustomRoleState, getFeatureFlagsSet],
  ({ productPermissions, permissionTableSearch }, userFeatureFlags) =>
    productPermissions.filter((permission) => {
      return (
        (!permissionTableSearch ||
          permission.name
            .toLowerCase()
            .includes(permissionTableSearch.toLowerCase())) &&
        new Set(
          permission.featureFlags?.map((flag) =>
            _toUpper(flag).replace(FEATURE_FLAG_PREFIX, '')
          ) ?? []
        ).isSubsetOf(userFeatureFlags)
      );
    })
);

export const getIsCustomRoleLoading = createSelector(
  [getCustomRoleState],
  ({ isLoading }) => isLoading
);

export const getPermissionTableSearch = createSelector(
  [getCustomRoleState],
  ({ permissionTableSearch }) => permissionTableSearch
);

export const getPermissionTableSelectedRows = createSelector(
  [getCustomRoleState],
  ({ permissionTableSelectedRows }) => permissionTableSelectedRows
);

export const getPermissionTableSelectedScopes = createSelector(
  [getCustomRoleState],
  ({ permissionTableSelectedScopes }) => permissionTableSelectedScopes
);

export const getSelectedPermissions = createSelector(
  [getCustomRoleState, getPermissionTableSelectedScopes],
  ({ productPermissions }, selectedScopes: PermissionTableScopes) =>
    productPermissions.filter((permission) => selectedScopes[permission.scope])
);

export const getActiveEditCustomRoleId = createSelector(
  [getCustomRoleState],
  ({ activeEditCustomRoleId }) => activeEditCustomRoleId
);

export const getBundledRoleState = createSelector(
  [getState],
  ({ bundledRoles }) => bundledRoles
);

export const getActiveEditBundledRoleId = createSelector(
  [getBundledRoleState],
  ({ activeEditBundledRoleId }) => activeEditBundledRoleId
);

export const getIsDiscardChangesModalVisible = createSelector(
  [getCustomRoleState],
  ({ isDiscardChangesModalVisible }) => isDiscardChangesModalVisible
);

export const getSummaryStepperBanner = createSelector(
  [getCustomRoleState],
  ({ toggleSummaryStepperBanner }) => toggleSummaryStepperBanner
);

export const getFetchEditCustomRole = createSelector(
  [getCustomRoleState],
  ({ fetchedEditCustomRole }) => fetchedEditCustomRole
);

export const getActiveEditCustomRoleName = createSelector(
  [getCustomRoleState],
  ({ fetchedEditCustomRole }) => fetchedEditCustomRole?.roleDisplayName
);

export const getStepper = createSelector(
  [getCustomRoleState],
  ({ stepper }) => stepper
);

export const getStepperStep = createSelector(
  [getStepper],
  ({ stepperStep }) => stepperStep
);

export const getActiveDeleteBundledRole = createSelector(
  [getState],
  ({ bundledRoles }) => bundledRoles.deleteBundledRole
);

export const getDeleteBundledRoleModalData = createSelector(
  [getState],
  ({ bundledRoles }) => ({
    roleName: bundledRoles.deleteBundledRole.roleDisplayName,
    roleId: bundledRoles.deleteBundledRole.id,
  })
);
export const getIsDeleteBundledRoleModalVisible = createSelector(
  [getBundledRoleState],
  ({ isDeleteBundledRoleModalVisible }) => ({
    isDeleteBundledRoleModalVisible,
  })
);

export const getProductOptions = createSelector(
  [getStepper],
  ({ productOptions, productField }) =>
    productField.value
      ? productOptions.items.map((option: CDSOption) => ({
          ...option,
          selected: option.value === productField.value?.value,
        }))
      : productOptions.items
);
export const getCustomRoleProducts = createSelector(
  [getCustomRoleState],
  ({ customRoleProducts }) => customRoleProducts
);

export const getProvisionedCustomRolesProductInstances = createSelector(
  [
    getCustomRoleProducts,
    getAllProvisionedProductsInstances,
    getProductOptions,
    getIsKPA,
    getKPAManagedTenants,
  ],
  (
    customRoleProducts,
    provisionedProductInstances,
    productOptions,
    isUserKpa,
    kpaManagedInstances
  ) => {
    const provisionedProductInstanceOptions =
      provisionedProductInstances.filter((productInstance) =>
        customRoleProducts.some(
          (product) => product.productKey === productInstance.productType
        )
      );
    const kpaManagedProductsOptions = getProductsManadgedByKpa(
      productOptions,
      kpaManagedInstances
    );

    return isUserKpa
      ? kpaManagedProductsOptions
      : provisionedProductInstanceOptions;
  }
);

export const getSelectedProductId = createSelector(
  [getStepper],
  ({ productField }) => productField?.value?.value
);

export const getSelectedProductKey = createSelector(
  [getStepper],
  ({ productField }) => productField?.value?.productKey
);

export const getIsProductOptionsLoading = createSelector(
  [getStepper],
  ({ productOptions }) => productOptions.isLoading
);

export const getRoleDetailsStepFields = createSelector(
  [getStepper],
  ({ productField, roleNameField, roleDescriptionField }): IRoleDetails => ({
    productField,
    roleNameField,
    roleDescriptionField,
  })
);

export const getIsCustomRoleDirty = createSelector(
  [getStepper],
  ({ productField, roleNameField }) =>
    Boolean(productField.value?.value ?? roleNameField.value)
);

export const getApplicationScopes = createSelector(
  [getCustomRoleState],
  ({ applicationScopes }) => applicationScopes
);

export const getProductCapabilities = createSelector(
  [getStepper, getCustomRoleState],
  ({ productCapabilities }, { productPermissions, applicationScopes }) =>
    productCapabilities.items.map((productCapability) => {
      return {
        ...productCapability,
        productPermission: {
          ...productCapability.productPermission,
          items:
            productCapability.productPermission?.items?.length > 0
              ? productCapability.productPermission.items
              : (productPermissions.map(({ name, scope }) => {
                  return { value: scope, label: name };
                }) as CDSOption[]),
        },
        productScope: {
          ...productCapability.productScope,
          items: {
            ...productCapability.productScope.items,
            options:
              productCapability.productScope?.items?.options.length > 0
                ? productCapability.productScope.items?.options
                : (
                    applicationScopes.map(({ name, id }) => {
                      return { value: id, label: name };
                    }) as CDSOption[]
                  ).slice(
                    0,
                    applicationScopes.length < 5 ? applicationScopes.length : 5
                  ),
            // TODO: Dynamically fetch the data from the backend based on the search once it’s implemented.
            // The purpose of this is just to show 5 out of xxx results.
            // This is temporary until we'll implement full search functionality with backend support.
          },
        },
      };
    })
);

export const getBundledRolesRows = createSelector(
  [getState],
  ({ bundledRoles }) => bundledRoles.bundledRolesData
);

export const getIsBundledEdit = createSelector(
  [getState],
  ({ bundledRoles }) => bundledRoles.isBundledEdit
);

export const getBundledStepper = createSelector(
  [getBundledRoleState],
  ({ stepper }) => stepper
);

export const getBundledStepperStep = createSelector(
  [getBundledStepper],
  ({ stepperStep }) => stepperStep
);

export const getProductsOptions = createSelector(
  [getState],
  ({ bundledRoles }) => bundledRoles.productsOptions
);

export const getRolesOptions = createSelector(
  [getState],
  ({ bundledRoles }) => bundledRoles.rolesOptions
);

export const getSelectedProducts = createSelector(
  [getState],
  ({ bundledRoles }) =>
    bundledRoles.bundledRolesData
      .map((row) => row.selectedProduct)
      .filter(Boolean)
);

export const getProductCapabilitiesTableData = createSelector(
  [getStepper, getSelectedProductKey],
  ({ productCapabilities }, productKey) =>
    productKey === ProductKeys.SECURE_WORKLOAD
      ? mapProductCapabilitiesToTableData(productCapabilities)
      : []
);
export const getActiveEditBundledRole = createSelector(
  [getBundledRoleState],
  ({ fetchedEditBundledRole }) => fetchedEditBundledRole
);

export const getBundleRoleDetailsStepFields = createSelector(
  [getBundledStepper],
  ({ roleNameField, roleDescriptionField }): IBundleRoleDetails => ({
    roleNameField,
    roleDescriptionField,
  })
);

export const getProductCapabilitiesTableDataChanges = createSelector(
  [getStepper, getCustomRoleState, getActiveEditCustomRoleId],
  (
    { productCapabilities },
    { capabilitiesTableSelectedScopes },
    activeEditCustomRoleId
  ) =>
    mapProductCapabilitiesToTableData(
      productCapabilities,
      !!activeEditCustomRoleId,
      capabilitiesTableSelectedScopes
    )
);

export const getRolesByPermissions = createSelector(
  [
    getAllRolesByExistingProducts,
    getKPAManagedProductsAndRoles,
    getIsKPA,
    getCustomRoleProducts,
  ],
  (allRoles, productRolesManagedByKPA, isKPA, customRoleProducts) => {
    return (isKPA ? productRolesManagedByKPA : allRoles).filter(
      (role) =>
        role.type !== UserRoleType.CUSTOM ||
        customRoleProducts.some(
          (product) => product.productKey === role.productKey
        )
    );
  }
);

export const getBundledCompare = createSelector(
  [getState],
  ({ bundledRoles }) => bundledRoles.bundledCompare
);
export const getIsBundledRoleDirty = createSelector(
  [getBundledStepper],
  ({ roleNameField, roleDescriptionField }) =>
    Boolean(roleNameField.value ?? roleDescriptionField.value)
);
export const getIsBundledDiscardChangesModalVisible = createSelector(
  [getState],
  ({ bundledRoles }) => bundledRoles.isDiscardChangesModalVisible
);
export const getIsBundledProductRoleEditStatus = createSelector(
  [getState],
  ({ bundledRoles }) => bundledRoles.isBundledProductRoleEditStatus
);
export const getIsBundledRoleLoading = createSelector(
  [getBundledRoleState],
  ({ isLoading }) => isLoading
);
