import { ISettingsParam } from 'tslog';

export const OVERVIEW_LEARN_MORE_LINK: string =
  'https://www.cisco.com/go/security-cloud-help';
export const PRIVACY_POLICY: string =
  'https://www.cisco.com/c/en/us/about/legal/privacy-full.html';
export const TERMS_SERVICES: string =
  'https://www.cisco.com/c/dam/en_us/about/doing_business/legal/Cisco_General_Terms.pdf';
export const CLAIM_NUMBER_DOCUMENTATION_LINK: string =
  'https://www.cisco.com/go/claim-subscription-help';

export const CONTACT_SUPPORT_LINK: string =
  'https://www.cisco.com/c/en/us/support/index.html';

export const CISCO_CONFIG_GUIDE_LINK: string =
  'https://www.cisco.com/c/en/us/td/docs/security/cloud_control/sso-secure-cloud-control-app/managing-products.html';

export const CISCO_REGION_SELECTION_GUIDE: string =
  'https://www.cisco.com/c/en/us/td/docs/security/cloud_control/security-provisioning-and-administration/managing-products.html';

export const CISCO_ENTERPRISE_DOCUMENTATION_LINK: string =
  'https://www.cisco.com/c/en/us/td/docs/security/cloud_control/security-provisioning-and-administration/managing-enterprises.html';

export const CISCO_SECURITY_CLOUD_CONTROL_USER_GUIDE: string =
  'https://www.cisco.com/c/en/us/td/docs/security/cloud_control/sso-secure-cloud-control-app/managing-users.html';

export const CISCO_IDP_GROUPS_DOCUMENTATION: string =
  'https://www.cisco.com/c/en/us/td/docs/security/cloud_control/sso-secure-cloud-control-app/managing-groups.html';
export const CISCO_OVERVIEW_DOCUMENTATION: string =
  'https://www.cisco.com/c/en/us/td/docs/security/cloud_control/sso-secure-cloud-control-app/security-cloud-control-overview.html';
export const SECURE_WORKLOAD_ROLES_DOCUMENTATION =
  'https://www.cisco.com/c/en/us/td/docs/security/workload_security/secure_workload/user-guide/3_9/cisco-secure-workload-user-guide-saas-v39/settings.html#concept_853093';
export const CISCO_SSE_PRODUCT = 'Cisco Secure Access'; // CSESC-3390.
/**
 * Strictly used uppercase to avoid String case-issue FEATURE_FLAG_PREFIX constant.
 **/
export const FEATURE_FLAG_PREFIX = 'MERCURY-FF-';
// export const FEATURE_FLAG_PREFIX = '';

// API error handling
export const X_TRACE_ID_RESPONSE_HEADER = 'x-trace-id';
export const X_PROMETHEUS_TRACE_ID_RESPONSE_HEADER = 'x-amzn-requestid';
export const GENERIC_ERROR = 'genericError';

export const LOGGER_DB_NAME: string = 'LoggerDataBase';
export const LOGGER_TABLE_NAME: string = 'Logger';
export const LEGACY: string = 'legacy';
export const EXTERNAL: string = 'external';
export const LOGGER_CONFIGURATION_PARAMETERS: ISettingsParam<any> = {
  name: 'PIAM-Logger',
  type: 'pretty',
  prettyLogTemplate:
    '{{yyyy}}.{{mm}}.{{dd}} {{hh}}:{{MM}}:{{ss}}:{{ms}}\t {{logLevelName}}\t[{{name}}]\t',
  prettyErrorTemplate:
    '\n{{errorName}} {{errorMessage}}\nerror stack:\n{{errorStack}}',
  prettyErrorStackTemplate:
    '  • {{fileName}}\t{{method}}\n\t{{filePathWithLine}}',
  prettyErrorParentNamesSeparator: ':',
  prettyErrorLoggerNameDelimiter: '\t',
  stylePrettyLogs: true,
  prettyLogTimeZone: 'UTC',
  prettyLogStyles: {
    logLevelName: {
      SILLY: ['bold', 'white'],
      TRACE: ['bold', 'whiteBright'],
      DEBUG: ['bold', 'green'],
      INFO: ['bold', 'blue'],
      WARN: ['bold', 'yellow'],
      ERROR: ['bold', 'red'],
      FATAL: ['bold', 'redBright'],
    },
    dateIsoStr: 'white',
    filePathWithLine: 'green',
    name: ['black', 'bold'],
    nameWithDelimiterPrefix: ['green', 'bold'],
    nameWithDelimiterSuffix: ['white', 'bold'],
    errorName: ['bold', 'bgRedBright', 'whiteBright'],
    fileName: ['yellow'],
  },
};

export const MIN_TABLE_PAGE_SIZE = 30;
export const PAGE_SIZE_OPTIONS = [MIN_TABLE_PAGE_SIZE, 50, 100];
export const TABLE_CONFIG = {
  pagination: true,
  paginationConfig: {
    pageSize: MIN_TABLE_PAGE_SIZE,
    showPageSizeChanger: true,
    pageSizeOptions: PAGE_SIZE_OPTIONS,
  },
};

export const EXTERNAL_SUB_QUERY_PARAM_VALUE = 'external';
export const EXTERNAL_SUB_QUERY_PARAM_KEY = 'sub';

export const dummyCert =
  'MIIDHTCCAgWgAwIBAgIJWc055/vMRWESMA0GCSqGSIb3DQEBCwUAMCwxKjAoBgNVBAMTIWRldi1za2V3OGQ1eDZuZzd6YmwzLnVzLmF1dGgwLmNvbTAeFw0yMjExMjkwNzE1MDNaFw0zNjA4MDcwNzE1MDNaMCwxKjAoBgNVBAMTIWRldi1za2V3OGQ1eDZuZzd6YmwzLnVzLmF1dGgwLmNvbTCCASIwDQYJKoZIhvcNAQEBBQADggEPADCCAQoCggEBAMLSSNe68gNRuApDOQD6FGyfyYDzzptpY2czyx2+WEu5PDXtAzsV9GPo3gWiNdnfwceafYJbD2KEkAwpR5OW3IPsyyQThDI0mR5P2r1+M1q3dj8dKahZ8pY/J40xTmPUpNx0LbFKvYODQTwU3eWcG15rYoHTwPvhfvW+Qivz18D5Mq+LXG5k9TSNMjYDIu6/iX/pk/n1SEIrUJXeg0722X5KzMBNRd5lrhXTnQ79OZbZpn0im4WY+CeK4PY4dMxzmPq+vl+Qf6BArT2ksFKnIZnowT/GVj6KRHFLzIytetL/hCcn4M8ikcLhB7ukAzsaL4GbSGkucoMynNWOadJNr+MCAwEAAaNCMEAwDwYDVR0TAQH/BAUwAwEB/zAdBgNVHQ4EFgQUI2nbLPM6uw37+4XwdGtCWF8Vka0wDgYDVR0PAQH/BAQDAgKEMA0GCSqGSIb3DQEBCwUAA4IBAQBfXO38WrZcUsk5X0uQMJgfaKgbT/8H6BU55X9d4wPA7PxNaG9uMAlgUW6BJKqg2E9MK3OIaS5cxtw00914dg01OqmlFVXIxFh7h3S0hZVak+4g1sTS1UIBY6gXHOCfSForJOXk/UeXWaSetMqgRK+Rd/sNH47E1iXnMc8G6ppmJIasJKizy7s0LP9c11YXFfma/F8ftDRqb6ZQU979CCqo+5DTSn5Uvsf7JwIrWPP38r86XUsGCLFqfLhn/83vAnidaGwHtiXfDEcGWo0CoPDJaGXqSqtmmSi/vpihzFWlr/0PCgqlHP7FxsJ2bzKyBNV6dcvjkOamYFzsLU+yCxRe';

/**
 * Strictly used uppercase to avoid String case-issue.
 **/
export const FEATURE_FLAG_CONSTANTS = {
  ENTERPRISE_NAVIGATION: 'ENTERPRISE-NAVIGATION',
  ACCESS_ENTERPRISE_BY_URL: 'ACCESS-ENTERPRISE-BY-URL',
  USER_INVITE_FLOWS: 'USER-INVITE-FLOWS',
  ADD_GROUP: 'ADD-GROUP',
  EDIT_GROUP: 'EDIT-GROUP',
  USER_ROLES_LIST: 'USER-ROLES-LIST',
  USER_MANAGEMENT: 'USER-MANAGEMENT',
  TENANT_LVL_ROLES_ASSIGNMENT: 'TENANT-LVL-ROLES-ASSIGNMENT',
  PERMISSION_AND_CUSTOM_ROLE_MANAGEMENT:
    'PERMISSION-AND-CUSTOM-ROLE-MANAGEMENT',
  MAP_IDP_GROUPS: 'MAP-IDP-GROUPS',
  EXTERNAL_IDP: 'EXTERNAL-IDP',
  CLAIM_CODE_MANAGEMENT: 'CLAIM-CODE-MANAGEMENT',
  BUNDLED_ROLE: 'BUNDLED-ROLE',
  ROLES_MANAGEMENT_DELEGATION: 'ROLES-MANAGEMENT-DELEGATION',
  INTEGRATIONS_MFE: 'INTEGRATIONS-MFE',
  RBAC_EVENT_LOG_VIEWER: '5297-RBAC-EVENT-LOG-VIEWER',
  SUBSCRIPTION_UI: 'SUBSCRIPTION-UI',
  UNIFIED_ONBOARDING: 'UNIFIED-ONBOARDING',
  ENHANCE_CUSTOM_ROLE_FOR_CSW: 'ENHANCE_CUSTOM_ROLE_FOR_CSW',
  V2_BUNDLED_ROLE: 'V2-BUNDLED-ROLE',
  UNIFIED_ONBOARDING_II: 'UNIFIED-ONBOARDING-II',
  SINGLE_TENANT_PER_ORG_UI: 'SINGLE-TENANT-PER-ORG-UI',
};

export const Trial = {
  name: 'trial',
  size: 'sm',
  dataTestId: 'ontrial-flag',
  tagContent: 'overview:trial',
  color: 'accentA',
};
export const InActive = {
  name: 'inactive',
  size: 'sm',
  dataTestId: 'inactive-flag',
  tagContent: 'overview:inActive',
};
export const Provisioned = {
  name: 'provisioned',
  size: 'sm',
  dataTestId: 'provisioned-tag',
  tagContent: 'overview:pendingStatus',
  status: 'warning',
};
export const Legacy = {
  name: 'legacy',
  size: 'sm',
  tagContent: '',
  dataTestId: 'is-legacy-externally-managed-flag',
};
export const External = {
  name: 'external',
  size: 'sm',
  tagContent: '',
  dataTestId: 'is-legacy-externally-managed-flag',
};

export const Activated = {
  name: 'activated',
  size: 'sm',
  tagContent: 'overview:activatedStatus',
  dataTestId: 'activated-tag',
  status: 'positive',
};

export const ENTERPRISE = 'Enterprise';

export const ENTERPRISE_PRODUCT_NAME =
  'Security Provisioning and Administration';

export const ENTERPRISE_PRODUCT_TYPE = 'SCC';

export const ENTERPRISE_OPTIONS = [
  {
    value: ENTERPRISE,
    label: ENTERPRISE,
    selected: true,
    disabled: true,
  },
];

export const DEFAULT = 'Default';

export const DEFAULT_OPTIONS = [
  {
    value: 'GLOBAL',
    label: 'Global',
    selected: true,
  },
];
export const products = [
  {
    id: '377dccc5-866b-44e3-8e6c-3b83daa25fc9',
    name: 'Cisco Secure Workload',
    region: {
      name: 'EU',
      description: 'Europe',
    },
  },
  {
    id: 'ead7204c-cda6-46d5-a516-731be896e5f7',
    name: 'Cisco Multicloud Defense',
    region: {
      name: 'APJC',
      description: 'Asia Pacific, Japan and China',
    },
  },
];

export const productsOrgEntitlements = [
  {
    productName: 'Cloud Defense Orchestrator',
    productId: '1234',
    selectedRegion: { label: 'North America', value: 'NAM' },
    availableDeploymentRegion: { label: 'North America', value: 'NAM' },
    isTrial: false,
  },
];

export const productsDataOrgEntitlements = [
  {
    id: '1234',
    name: 'Cloud Defense Orchestrator',
    region: {
      name: 'NAM',
      description: 'North America',
    },
  },
];

export const tablePaginationConfigWithoutPageSizeChanger = {
  pageSize: 10,
  showPageSizeChanger: false,
};
export const regionData = [
  {
    label: 'Asia, Pacific, Japan, China',
    value: '1',
  },
  {
    label: 'Europe',
    value: '2',
  },
  {
    label: 'North America',
    value: '3',
  },
];
export const countryData = [
  {
    label: 'Canada',
    value: '1',
  },
  {
    label: 'United States',
    value: '2',
  },
];

export const APPLICATION_PORTAL_LINK =
  'https://apps.security.cisco.com/overview';

export const orgEntitlementsProducts = [
  'Cisco Multicloud Defense',
  'Cisco Defense Orchestrator',
  'Cisco Multicloud Defense',
  'Cisco Firewall Cloud Management',
  'Hypershield',
];
