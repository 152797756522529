import { combineReducers, AnyAction } from 'redux';

import activityLogReducer from 'src/app/components/activity-log/reducer';
import idpReducer from 'src/app/components/idp-management/reducer';
import { ResetStore } from './actions';
import { IState } from './interfaces';
import { getResetStateByKeys } from './utils/action-utils';
import commonReducer from '../../components/common/reducer';
import enterprisesReducer from '../../components/enterprises/reducer';
import { errorsReducer } from '../../components/errors/reducer';
import groupsReducer from '../../components/groups/reducer';
import { loaderReducer } from '../../components/loader/reducer';
import overviewReducer from '../../components/overview/store/reducer';
import userRolesReducer from '../../components/user-roles/reducer';
import usersReducer from '../../components/users/reducer';
import configReducer from '../../config/reducer';

const createRootReducer = (): any => {
  const appReducer = combineReducers({
    activityLog: activityLogReducer,
    enterprises: enterprisesReducer,
    overview: overviewReducer,
    users: usersReducer,
    userRoles: userRolesReducer,
    groups: groupsReducer,
    common: commonReducer,
    config: configReducer,
    loader: loaderReducer,
    errors: errorsReducer,
    idp: idpReducer,
  });

  const rootReducer = (
    state: IState,
    action: AnyAction
  ): ReturnType<typeof appReducer> => {
    if (action.type === ResetStore.TYPE) {
      return appReducer(
        getResetStateByKeys(state, [
          'common',
          'idp',
          'groups',
          'users',
          'userRoles',
          'enterprises',
          'overview',
          'errors',
          'activityLog',
        ]),
        action
      );
    }
    return appReducer(state, action);
  };

  return rootReducer;
};

export default createRootReducer;
