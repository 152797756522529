import { put, select, takeLatest, delay, call } from 'redux-saga/effects';
import { prometheusAPIClient } from 'src/app/api/clients';
import { IActionWithPayload } from 'src/app/core/redux/interfaces';
import {
  IGetIdp,
  IVerifyIdpResponse,
} from 'src/app/interfaces/IIdentityProvider';
import { notifyFormPopulated } from 'src/app/services/formPopulated';
import {
  FetchIdentityProviders,
  FetchExternalIdentityProviders,
  VerifyExternalIdp,
  CreateExternalIdp,
  ChangeAddExternalIdpModalStep,
  AddIdpNotification,
  HideAddExternalIdpModal,
  ClearAddExternalIdpData,
} from './actions';
import { IdpNotificationType } from '../../enums/Idps';
import { getEnterpriseId } from '../enterprises/selectors';

export function* getIdp(): Generator {
  const enterpriseId = (yield select(getEnterpriseId)) as string;

  try {
    const response: IGetIdp = yield prometheusAPIClient.get(
      `/api/enterprises/${enterpriseId}/idps`
    );
    const idps = response.data;
    yield put(FetchIdentityProviders.SUCCESS(idps));
  } catch (error) {
    yield put(FetchIdentityProviders.ERROR(error as Error));
  }
}
export function* getExternalIdps(): Generator {
  const enterpriseId = (yield select(getEnterpriseId)) as string;

  try {
    const response: IGetIdp = yield prometheusAPIClient.get(
      `/api/enterprises/${enterpriseId}/authorizedExternalIdps`
    );
    const idps = response.data;
    yield put(FetchExternalIdentityProviders.SUCCESS(idps));
  } catch (error) {
    yield put(FetchExternalIdentityProviders.ERROR(error as Error));
  }
}

export function* verifyExternalIdp(
  action: IActionWithPayload<string>
): Generator {
  try {
    const externalIdpId = action.payload;
    const response: IVerifyIdpResponse = yield prometheusAPIClient.get(
      `/api/verifyIdP/${externalIdpId}`
    );
    const externalIdp = response.data;
    yield put(VerifyExternalIdp.SUCCESS(externalIdp));
    yield put(ChangeAddExternalIdpModalStep('confirm'));
  } catch (error) {
    yield put(VerifyExternalIdp.ERROR(error as Error));
    yield put(
      AddIdpNotification({
        type: IdpNotificationType.CREATE_EXTERNAL_IDP_ERROR,
        traceId: (error as any)?.response?.data?.traceId ?? null,
      })
    );
    yield put(HideAddExternalIdpModal());
    yield put(ClearAddExternalIdpData());
    yield delay(1000);
    yield call(notifyFormPopulated, { isDirty: true });
  }
}

export function* createExternalIdp(
  action: IActionWithPayload<string>
): Generator {
  const enterpriseId = (yield select(getEnterpriseId)) as string;

  try {
    const externalIdpId = action.payload;
    yield prometheusAPIClient.post(
      `/api/enterprises/${enterpriseId}/authorizedExternalIdps`,
      {
        idpId: externalIdpId,
      }
    );
    yield put(CreateExternalIdp.SUCCESS());
    yield put(HideAddExternalIdpModal());
    yield put(ClearAddExternalIdpData());
    yield put(
      AddIdpNotification({
        type: IdpNotificationType.CREATE_EXTERNAL_IDP_SUCCESS,
      })
    );
    yield put(FetchExternalIdentityProviders.PENDING());
  } catch (error) {
    yield put(CreateExternalIdp.ERROR(error as Error));
    yield put(
      AddIdpNotification({
        type: IdpNotificationType.CREATE_EXTERNAL_IDP_ERROR,
        traceId: (error as any)?.response?.data?.traceId ?? null,
      })
    );
    yield put(HideAddExternalIdpModal());
    yield put(ClearAddExternalIdpData());
    yield delay(1000);
    yield call(notifyFormPopulated, { isDirty: true });
  }
}

export function* idpSaga(): Generator {
  yield takeLatest(FetchIdentityProviders.Pending.TYPE, getIdp);
  yield takeLatest(VerifyExternalIdp.Pending.TYPE, verifyExternalIdp);
  yield takeLatest(CreateExternalIdp.Pending.TYPE, createExternalIdp);
  yield takeLatest(
    FetchExternalIdentityProviders.Pending.TYPE,
    getExternalIdps
  );
}
