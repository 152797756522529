import {
  FETCH_INVITE_PRODUCTS_PENDING,
  FETCH_INVITE_PRODUCTS_SUCCESS,
  FETCH_INVITE_PRODUCTS_ERROR,
  FETCH_PRODUCTS_LIST_PENDING,
  FETCH_PRODUCTS_LIST_SUCCESS,
  FETCH_PRODUCTS_LIST_ERROR,
  ATTACH_PRODUCT_INVITE_PENDING,
  ATTACH_PRODUCT_INVITE_SUCCESS,
  ATTACH_PRODUCT_INVITE_ERROR,
  DECLINE_PRODUCT_INVITE_PENDING,
  DECLINE_PRODUCT_INVITE_SUCCESS,
  DECLINE_PRODUCT_INVITE_ERROR,
  SHOW_ATTACH_PRODUCT_MODAL,
  HIDE_ATTACH_PRODUCT_MODAL,
  SHOW_DECLINE_PRODUCT_MODAL,
  HIDE_DECLINE_PRODUCT_MODAL,
  HIDE_SUCESS_PRODUCT_NOTIFICATION,
  HIDE_ERROR_PRODUCT_NOTIFICATION,
  SHOW_SUCCESS_ATTACH_PRODUCT_NOTIFICATION,
  SHOW_SUCCESS_DECLINE_PRODUCT_NOTIFICATION,
  SHOW_ERROR_ATTACH_PRODUCT_NOTIFICATION,
  SHOW_ERROR_DECLINE_PRODUCT_NOTIFICATION,
  POST_BOOTSTRAP_PLATFORM_SUBSCRIPTION,
} from './constants';
import {
  AttachInviteProductType,
  IAction,
  IActionWithEnterpriseId,
  IActionWithPayload,
  ProductModalType,
} from '../../../interfaces/IOverview';

export const fetchInviteProducts = (): IAction => ({
  type: FETCH_INVITE_PRODUCTS_PENDING,
});

export const fetchInviteProductsSuccess = (
  data: object
): IActionWithPayload => ({
  type: FETCH_INVITE_PRODUCTS_SUCCESS,
  payload: data,
});

export const fetchInviteProductsError = (error: Error): IActionWithPayload => ({
  type: FETCH_INVITE_PRODUCTS_ERROR,
  payload: error,
});

export const fetchProductsList = (): IAction => ({
  type: FETCH_PRODUCTS_LIST_PENDING,
});

export const fetchProductsListSuccess = (data: object): IActionWithPayload => ({
  type: FETCH_PRODUCTS_LIST_SUCCESS,
  payload: data,
});

export const fetchProductsListError = (error: Error): IActionWithPayload => ({
  type: FETCH_PRODUCTS_LIST_ERROR,
  payload: error,
});

export const attachInviteProduct = (
  invitationId: string,
  enterpriseId: string
): AttachInviteProductType => ({
  type: ATTACH_PRODUCT_INVITE_PENDING,
  payload: { invitationId, enterpriseId },
});

export const attachInviteProductSuccess = (): IAction => ({
  type: ATTACH_PRODUCT_INVITE_SUCCESS,
});

export const attachInviteProductError = (error: Error): IActionWithPayload => ({
  type: ATTACH_PRODUCT_INVITE_ERROR,
  payload: error,
});

export const declineInviteProduct = (
  invitationId: string
): IActionWithPayload => ({
  type: DECLINE_PRODUCT_INVITE_PENDING,
  payload: { invitationId },
});

export const declineInviteProductSuccess = (): IAction => ({
  type: DECLINE_PRODUCT_INVITE_SUCCESS,
});

export const declineInviteProductError = (
  error: Error
): IActionWithPayload => ({
  type: DECLINE_PRODUCT_INVITE_ERROR,
  payload: error,
});

export const showAttachProductModal = (
  openedInvitationId: string
): ProductModalType => ({
  type: SHOW_ATTACH_PRODUCT_MODAL,
  payload: openedInvitationId,
});

export const hideAttachProductModal = (): IAction => ({
  type: HIDE_ATTACH_PRODUCT_MODAL,
});

export const showDeclineProductModal = (
  openedInvitationId: string
): ProductModalType => ({
  type: SHOW_DECLINE_PRODUCT_MODAL,
  payload: openedInvitationId,
});

export const hideDeclineProductModal = (): IAction => ({
  type: HIDE_DECLINE_PRODUCT_MODAL,
});

export const showSuccessAttachProductNotification = (): IAction => ({
  type: SHOW_SUCCESS_ATTACH_PRODUCT_NOTIFICATION,
});

export const showSuccessDeclineProductNotification = (): IAction => ({
  type: SHOW_SUCCESS_DECLINE_PRODUCT_NOTIFICATION,
});

export const hideSuccessProductNotification = (): IAction => ({
  type: HIDE_SUCESS_PRODUCT_NOTIFICATION,
});

export const showErrorAttachProductNotification = (
  statusCode: number | null = null
): IAction => ({
  type: SHOW_ERROR_ATTACH_PRODUCT_NOTIFICATION,
  payload: statusCode,
});

export const showErrorDeclineProductNotification = (): IAction => ({
  type: SHOW_ERROR_DECLINE_PRODUCT_NOTIFICATION,
});

export const hideErrorProductNotification = (): IAction => ({
  type: HIDE_ERROR_PRODUCT_NOTIFICATION,
});

export const sendBootstrapPlatformRequest = (
  enterpriseId: string,
  enterpriseName: string
): IActionWithEnterpriseId => ({
  type: POST_BOOTSTRAP_PLATFORM_SUBSCRIPTION,
  enterpriseId,
  enterpriseName,
});
