export const USER_ROLES_SEARCH_DATA_KEYS: string[] = [
  'roleDisplayName',
  'roleDescription',
  'product',
];

export const ASSIGNED_GROUPS_ROLE_SEARCH_DATA_KEYS: string[] = ['name'];
export const ASSIGNED_USERS_ROLE_SEARCH_DATA_KEYS: string[] = [
  'email',
  'firstName',
  'lastName',
];
export const ASSIGNED_USERS_ROLE_PERMISSIONS_SEARCH_DATA_KEYS: string[] = [
  'name',
  'description',
];

export const ASSIGNED_PRODUCT_ROLES_SEARCH_DATA_KEYS: string[] = [
  'roleDisplayName',
  'roleDescription',
  'product',
];

export const MAX_INHERITED_ROLES_VISIBLE_TEXT_LENGHT = 65;

export const FIELD_LIMITS = {
  MAX_LIMIT_50: 50,
  MAX_LIMIT_256: 256,
};

export const STATUS = {
  UNCHANGED: 'Unchanged',
  CHANGED: 'Changed',
  DELETED: 'Deleted',
  ADDED: 'Added',
};

export const ERROR = 'ERROR';
export const API_ERROR_USER_ASSIGNED_TO_ROLE =
  'error creating user roles assignment for user';
export const API_ERROR_GROUP_ASSIGNED_TO_ROLE =
  'error creating RBAC group roles assignment';

export const NOT_FOUND_PAGE_LOADER_TIMEOUT = 3000;
