import { createSelector } from 'reselect';

import { IState } from 'src/app/core/redux/interfaces';
import { IIdp } from 'src/app/interfaces/IIdentityProvider';

export const getState = (state: IState): IIdp => state.idp || {};

export const getIdp = createSelector([getState], ({ idp }) => idp?.data ?? []);

export const getExternalIdp = createSelector(
  [getState],
  ({ externalIdp }) => externalIdp?.data ?? []
);

export const getIdpData = createSelector([getIdp], (idps) =>
  idps.map((item) => ({
    name: item.inputs.idpName,
    link: item.outputs.acsUrl,
  }))
);

export const getIsAddExternalIdpModalVisible = createSelector(
  [getState],
  ({ addExternalIdp }) =>
    addExternalIdp?.addExternalIdpModal?.isModalVisible ?? false
);

export const getAddExternalIdpModalStep = createSelector(
  [getState],
  ({ addExternalIdp }) => addExternalIdp?.addExternalIdpModal?.step ?? 'add'
);

export const getAddExternalIdpModalIdpIdInputValue = createSelector(
  [getState],
  ({ addExternalIdp }) =>
    addExternalIdp?.addExternalIdpModal?.idpIdInputValue ?? ''
);

export const getVerifyExternalIdpLoadingStatus = createSelector(
  [getState],
  ({ addExternalIdp }) =>
    addExternalIdp?.externalIdpToBeAdded?.isLoading ?? false
);

export const getAddExternalIdpData = createSelector(
  [getState],
  ({ addExternalIdp }) => addExternalIdp?.externalIdpToBeAdded?.data ?? null
);

export const getAddExternalIdpError = createSelector(
  [getState],
  ({ addExternalIdp }) => addExternalIdp?.externalIdpToBeAdded?.error ?? null
);

export const getIdpNotification = createSelector(
  [getState],
  ({ notification }) => ({
    notificationType: notification.type,
    traceId: notification?.traceId ?? null,
  })
);

export const getExternalIdpData = createSelector([getExternalIdp], (idps) =>
  idps.map((item) => ({
    name: item.idpName,
    link: item.idpDomain,
  }))
);
