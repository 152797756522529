import {
  IActivityLog,
  DateRangePickerValue,
  EventType,
} from 'src/app/interfaces/IActivityLog';
import { eventTypeMapping } from './constants';
import { IUser } from '../../interfaces/ICommon';
import { IOption } from '../groups/interfaces';

export const getLogUsersEmails = (
  activityLogData: IUser[],
  selectedUsers: string[]
): IOption[] => {
  if (!activityLogData) return [];
  return activityLogData.map((log) => {
    const email = log.profile.email;
    const isSelected = selectedUsers.includes(email);
    return {
      label: email,
      value: email,
      selected: isSelected,
    };
  });
};

export const getSubTypes = (selectedSubtypes: string[]): IOption[] => {
  return Object.entries(eventTypeMapping).map(([eventType, label]) => {
    const isSelected = selectedSubtypes.includes(eventType);
    return {
      label,
      value: eventType,
      selected: isSelected,
    };
  });
};

export const formatDateToMMDDYYYY = (dateInput: string | Date): string => {
  const date = typeof dateInput === 'string' ? new Date(dateInput) : dateInput;

  const options: Intl.DateTimeFormatOptions = {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  };

  return date.toLocaleDateString('en-US', options);
};

export function getInitialDefaultRange(createdAt: string): [Date, Date] {
  const createdAtDate = new Date(createdAt);
  const endDate = new Date();

  const ninetyDaysAgo = new Date();
  ninetyDaysAgo.setDate(endDate.getDate() - 90);

  const startDate =
    createdAtDate < ninetyDaysAgo ? ninetyDaysAgo : createdAtDate;

  return [startDate, endDate];
}

export function parseDateRangePickerValue(
  initialFetchedRange: DateRangePickerValue
): DateRangePickerValue {
  if (initialFetchedRange && initialFetchedRange.length === 2) {
    return [
      typeof initialFetchedRange[0] === 'string'
        ? new Date(initialFetchedRange[0])
        : initialFetchedRange[0],
      typeof initialFetchedRange[1] === 'string'
        ? new Date(initialFetchedRange[1])
        : initialFetchedRange[1],
    ];
  }
  return initialFetchedRange === null ? null : undefined;
}

export function formatTimestamp(timestamp: string): string {
  const date = new Date(timestamp);
  return new Intl.DateTimeFormat('en-US', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  }).format(date);
}

function formatLog(
  log: IActivityLog
): IActivityLog & { eventType: string; eventDate: string } {
  const formattedTimestamp = formatTimestamp(log.eventDate);
  return {
    ...log,
    eventType: eventTypeMapping[log.eventType as EventType],
    eventDate: formattedTimestamp as EventType,
  };
}

export function formatLogs(
  logs: IActivityLog[]
): Array<IActivityLog & { eventType: string }> {
  if (Array.isArray(logs)) {
    return logs.map(formatLog);
  } else {
    return [];
  }
}

export const convertSortToBackendFormat = (
  sortArray: Array<{ id: string; desc: boolean }>
): string | null => {
  if (sortArray.length === 0) {
    return '';
  }

  const { id, desc } = sortArray[0];
  const sortOrder = desc ? 'desc' : 'asc';

  return `${id}:${sortOrder}`;
};
